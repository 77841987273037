import Vue from 'vue'
import VueRouter from 'vue-router'
const Main = () => import("../views/Main.vue");
const How = () => import("../views/How.vue");
Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'Main',
    component: Main
  },
  {
    path: '/how-it-works',
    name: 'How',
    component: How
  },
  
  
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

export default router
