import Vue from 'vue'
import App from './App.vue'
import router from './router'
import './main.css'
Vue.config.productionTip = false

new Vue({
  router,
  data:{
    d:false,
  },
  mounted(){
    let mode = localStorage.getItem("claimelly-dark");
    console.log("value got from local storage of MODE", mode)
    if(mode !== undefined) {
      this.d = (mode == "false") ? false : true;
      console.log("mode got value, set from localstroage")
    }
    else {
      console.log("mode got no value, set from default")
      this.d = false
      localStorage.setItem("claimelly-dark", false)
    }
  },
  watch:{
    
  },
  render: h => h(App),
}).$mount('#app')
