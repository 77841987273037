<template>
  <div class='mx-width-extra py-lg-5' :class='{"bg-light":$root.d,"bg-dark":!$root.d}'>
    <div class="position-ralative">
      <button class="btn btn-link position-absolute d-none d-lg-block"
      @click='goTo("How")'
      :class='{"text-primary":$root.d,"text-white":!$root.d}'
       style='right:11%;'> <h3>How It Works</h3></button>
      <button class="position-absolute btn btn-primary-outline btn-mode" @click='change'>
        <img v-if='$root.d' src="@/assets/sun-color.png" width="103" height="40" alt="light">
        <img v-else src="@/assets/moon-black.png" width="103" height="40" alt="dark">
      </button>
    </div>
      <img v-if='$root.d' @click='goTo("Main")' src="@/assets/logo.png" class='link logo-image d-none d-lg-block' alt="" srcset="">
      <img v-if='$root.d' @click='goTo("Main")' src="@/assets/logo.png" class='link d-lg-none' 
      style='width: 50%;min-width:200px;
    margin: 8px 0px 0px 5px;' alt="" srcset="">

    <img v-if='!$root.d' @click='goTo("Main")' src="@/assets/logo-dark.png" class='link logo-image d-none d-lg-block' alt="" srcset="">
      <img v-if='!$root.d' @click='goTo("Main")' src="@/assets/logo-dark.png" class='link d-lg-none' style='width: 50%;min-width:200px;
    margin: 8px 0px 0px 5px;' alt="" srcset="">
    <button class="btn btn-link d-lg-none d-block"
      @click='goTo("How")'
      :class='{"text-primary":$root.d,"text-white":!$root.d}'
       > <span>How It Works</span></button>

  </div>
</template>

<script>
export default {
    data() {
        return {
            toggled: false
        }
    },
    computed:{
        route() {
            return this.$route.name
        }
    },
    methods:{
      change(){
        this.$root.d = !this.$root.d
        console.log("change detected, updaing S value as", this.$root.d)
        localStorage.setItem("claimelly-dark", this.$root.d)
      },
      goTo(name){
        this.$router.push({name})
      }
    }
}
</script>

<style scoped>
* {
  box-sizing: border-box;
}

body {
  margin: 0px;
  font-family: 'segoe ui';
}

.nav {
  height: auto;
  width: 100%;
  background-color: rgb(234, 235, 237);
  position: relative;
}

.nav > .nav-header {
  display: inline;
}

.nav > .nav-header > .nav-title {
  display: inline-block;
  font-size: 1rem;
    font-family: "Calibri";
    color: rgb(40, 45, 66);
  padding: 10px 10px 10px 10px;
}

.nav > .nav-btn {
  display: none;
}

.nav > .nav-links {
  display: inline;
  float: right;
  height: 400px;
  font-size: 18px;
}

.nav > .nav-links > span {
  display: inline-block;
  padding: 13px 10px 13px 10px;
  text-decoration: none;
  font-size: 1rem;
    font-family: "Calibri";
    color: rgb(40, 45, 66);
    display: inline-block !important;
}

.nav > .nav-links > a:hover {
  background-color:rgb(234, 235, 237);
}

.nav > #nav-check {
  display: none;
}

@media (max-width:768px) {
  .nav > .nav-btn {
    display: inline-block;
    position: absolute;
    right: 0px;
    top: 0px;
  }
  .nav > .nav-btn > label {
    display: inline-block;
    width: 50px;
    height: 50px;
    padding: 13px;
  }
  /* .nav > .nav-btn > label:hover,.nav  #nav-check:checked ~ .nav-btn > label {
    background-color:rgb(234, 235, 237);
  } */
  .nav > .nav-btn > label > span {
    display: block;
    width: 25px;
    height: 10px;
    border-top: 2px solid rgb(40, 45, 66);;
    
  }
  .nav > .nav-links {
    position: absolute;
    display: block;
    z-index:999;
    width: 100%;
    background-color: rgb(234, 235, 237);
    /* height: 0px; */
    transition: all 0.3s ease-in;
    overflow-y: hidden;
    top: 50px;
    left: 0px;
  }
  .nav > .nav-links > a {
    display: block;
    width: 100%;
  }
  /* .nav > #nav-check:not(:checked) ~ .nav-links {
    height: 0px;
  }
  .nav > #nav-check:checked ~ .nav-links {
    height: calc(100vh - 50px);
    overflow-y: auto;
  } */
}
</style>